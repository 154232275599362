<div class="container mat-elevation-z8">
  <div class="row">
    <div class="col">
      <h3>Caregivers</h3>
    </div>
    <div class="col text-end">
      <button mat-raised-button class="ml-auto d-flex" color="primary" (click)="addCaregiver()">Add</button>
    </div>
  </div>
  <mat-divider class="mb-3"></mat-divider>
  <div class="row">
    <div class="col-12" *ngFor="let item of caregivers">
      <div class="row" (click)="chooseItem(item._id)">
        <div class="col-12 item">
          <img src="{{item.profilePic !== '' ? imgageUrl+item.profilePic:'../../../assets/avatar.svg'}}"
            class="img-fluid avatar">
          <div class="w-100">
            <div class="row d-flex justify-content-between">
              <!-- <div class="col-md-8"> -->
              <div>
                <h4>{{item.name}}</h4>
                <p>{{item.email}}</p>
                <p>{{item.work_address_name}}</p>
              </div>
              <!-- </div> -->
              <div class="d-flex flex-column align-items-end mr-3">
                <div class="col-md"><mat-icon *ngFor="let item of createStars(item.avgRating)">{{item ? 'star' :
                    'star_border'}}</mat-icon></div>
                <button (click)="openConfirmDialog($event,item._id)" class="list-item" mat-stroked-button aria-label="Example icon button with a vertical three dot icon"
                  style="width: 40px;height:40px;padding:0px;min-width:40px;">
                  <mat-icon>delete</mat-icon>
                </button>
                <p class="timem">5 Miles away</p>
              </div>
            </div>


          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="row" *ngIf="caregivers.length <= 0"><div class="col-12 text-center">No data found</div></div>
  </div>