<!-- Side-Nav -->
<mat-divider></mat-divider>
<div class="side-navbar active-nav d-flex justify-content-between flex-wrap flex-column" id="sidebar">
  <ul class="nav flex-column text-white w-100">
    
      <h5>MENU</h5>
      <a [routerLink]="[{outlets: {dashboard: 'profile'}}]" routerLinkActive="active" class="nav-link">
        <span class="mx-2">
          <mat-icon>account_circle</mat-icon><span class="inner">Profile</span>
        </span>
      </a>
      <a [routerLink]="[{outlets: {dashboard: 'search'}}]" routerLinkActive="active" class="nav-link">
        <span class="mx-2">
          <mat-icon>search</mat-icon><span class="inner">Search</span>
        </span>
      </a>
      <a [routerLink]="[{outlets: {dashboard: 'notifications'}}]" routerLinkActive="active" class="nav-link">
        <span class="mx-2">
          <mat-icon>notifications</mat-icon><span class="inner">Notifications</span>
        </span>
      </a>
      <a [routerLink]="[{outlets: {dashboard: 'messages'}}]" routerLinkActive="active" class="nav-link">
        <span class="mx-2">
          <mat-icon>chat_bubble</mat-icon><span class="inner">Messages</span>
        </span>
      </a>
      <a [routerLink]="[{outlets: {dashboard: 'list'}}]" routerLinkActive="active" class="nav-link">
        <span class="mx-2">
          <mat-icon>medication</mat-icon><span class="inner">Caregivers</span>
        </span>
      </a>
      <h5>SETTINGS</h5>
      <a [routerLink]="[{outlets: {dashboard: 'change-password'}}]" routerLinkActive="active" class="nav-link">
        <span class="mx-2">
          <mat-icon>lock</mat-icon><span class="inner">Change Password</span>
        </span>
      </a>
      <a href="https://apps.apple.com/us/app/icaregiver/id1538926952" class="nav-link">
        <span class="mx-2">
          <mat-icon>stars</mat-icon><span class="inner">Rate & Review</span>
        </span>
      </a>
      <a class="nav-link" href="mailto: ph@myicaregiver.com">
        <span class="mx-2">
          <mat-icon>headset</mat-icon><span class="inner">Help & Support</span>
        </span>
      </a>
      <a href="https://api.myicaregiver.com/page/termsCondition.html" class="nav-link">
        <span class="mx-2">
          <mat-icon><span class="material-symbols-outlined">
              content_copy
            </span></mat-icon><span class="inner">Terms & Conditions</span>
        </span>
      </a>
      <a href="https://api.myicaregiver.com/page/privacyPolicy.html" class="nav-link">
        <span class="mx-2">
          <mat-icon>policy</mat-icon><span class="inner">Privacy Policy</span>
        </span>
      </a>
      <a href="https://api.myicaregiver.com/page/disclaimer.html" class="nav-link">
        <span class="mx-2">
          <mat-icon>warning</mat-icon><span class="inner">Disclaimer</span>
        </span>
      </a>
  </ul>
</div>