<form class="example-form" [formGroup]="registerFormGroup" (submit)="register()">
    <div class="row">
        <div class="col-md-6">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput type="text" formControlName="name">
                <mat-error *ngIf="registerFormGroup.get('name')?.hasError('required')">
                    Name is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput type="text" formControlName="email">
                <mat-error *ngIf="registerFormGroup.get('email')?.hasError('required')">
                    Email is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="!registerFormGroup.get('email')?.hasError('required') && registerFormGroup.get('email')?.hasError('email')">
                    Enter valid email address
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Bio</mat-label>
                <textarea matInput type="text" formControlName="bio"></textarea>
                <mat-error *ngIf="registerFormGroup.get('bio')?.hasError('required')">
                    Bio is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Choose a date</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="dob">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field> -->
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Age</mat-label>
                <mat-select formControlName="age">
                    <mat-option value="{{age}}" *ngFor="let age of createRange(75)">{{age}} Years</mat-option>
                </mat-select>
                <mat-error *ngIf="registerFormGroup.get('gender')?.hasError('required')">
                    Age is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Gender</mat-label>
                <mat-select formControlName="gender">
                    <mat-option value="1">Male</mat-option>
                    <mat-option value="2">Female</mat-option>
                    <mat-option value="3">Others</mat-option>
                </mat-select>
                <mat-error *ngIf="registerFormGroup.get('gender')?.hasError('required')">
                    Gender is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Agency</mat-label>
                <input matInput type="text" value="{{agencyName.name}}" [disabled]="true">
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field class="example-full-width" appearance="outline" (click)="openLocationDialogue($event)">
                <mat-label>Location</mat-label>
                <input matInput type="text" disabled="true" [value]="address.address_name">
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>City</mat-label>
                <input matInput type="text" disabled="true" [value]="address.city">
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Zipcode</mat-label>
                <input matInput type="text" disabled="true" [value]="address.zipcode">
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Highlight</mat-label>
                <textarea matInput type="text" formControlName="highlights"></textarea>
            </mat-form-field>
            <button class="mt-2" [disabled]="!registerFormGroup.valid" type="submit" mat-raised-button
                color="primary">Submit</button>
        </div>
    </div>
</form>