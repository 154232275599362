<div class="container mat-elevation-z8">
  <div class="row">
    <mat-form-field class="example-full-width" appearance="outline" (click)="openMapBox()">
      <input matInput placeholder="Enter to search" [value]="address.zipcode" [disabled]="true">
    </mat-form-field>
    <div class="row list-item" *ngFor="let item of jobs" (click)="chooseItem(item._id)">
      <div class="col-1">
        <app-searchicon
          [text]="((item.createdBy.createdAt*1000) | date:'dd')?.substring(0,3)+' '+((item.createdBy.createdAt*1000) | date:'MMMM')?.substring(0,3)">
        </app-searchicon>
      </div>
      <div class="col">
        <h5>{{item.title}}</h5>
        <h6>{{item.description}}</h6>
        <p>{{item.address}}</p>
        <p>{{item.createdBy.name}}</p>
      </div>
    </div>
    <h6 class="mt-3 text-center w-100" *ngIf="jobs.length <= 0">No Data Found</h6>
  </div>
</div>