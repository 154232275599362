<div class="container">
    <div class="row">
        <div class="col-12">
            <mat-card class="mat-elevation-z8">
                <div class="row">
                    <div class="col-12">
                        <div class="col-12 text-center">
                            <h2>Forgot Password</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <form class="example-form" [formGroup]="forgotPasswordGroup" (submit)="login()">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput type="email" formControlName="email">
                            <mat-error
                                *ngIf="forgotPasswordGroup.get('email')?.hasError('email') && !forgotPasswordGroup.get('email')?.hasError('required')">
                                Please enter a valid email address
                            </mat-error>
                            <mat-error *ngIf="forgotPasswordGroup.get('email')?.hasError('required')">
                                Email is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <button class="mt-2 mb-4" [disabled]="!forgotPasswordGroup.valid" type="submit" mat-raised-button color="primary">Submit</button>
                    </form>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6 p-0">
                        <a routerLink="/login">Login here</a>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>

<app-spinner [class.hide]="hideloader"></app-spinner>