<div class="container mat-elevation-z8">
    <div class="row">
        <div class="col">
            <mat-selection-list [multiple]="false">
                <mat-list-option>
                    <mat-icon mat-list-icon src="../../../assets/avatar.svg">settings</mat-icon>
                    <div mat-line>Change Password</div>
                </mat-list-option>
                <mat-list-option>
                    <mat-icon mat-list-icon src="../../../assets/avatar.svg">settings</mat-icon>
                    <div mat-line>Change Password</div>
                </mat-list-option>
                <mat-list-option>
                    <mat-icon mat-list-icon src="../../../assets/avatar.svg">settings</mat-icon>
                    <div mat-line>Change Password</div>
                </mat-list-option>
                <mat-list-option>
                    <mat-icon mat-list-icon src="../../../assets/avatar.svg">settings</mat-icon>
                    <div mat-line>Change Password</div>
                </mat-list-option>
                <mat-list-option>
                    <mat-icon mat-list-icon src="../../../assets/avatar.svg">settings</mat-icon>
                    <div mat-line>Change Password</div>
                </mat-list-option>
            </mat-selection-list>
        </div>
    </div>
</div>