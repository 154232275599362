<div class="container">
    <div class="row">
        <div class="col-12">
            <mat-card class="mat-elevation-z8">
                <div class="row">
                    <div class="col-12">
                        <div style="margin:auto;width:fit-content;">
                            <img src="../../../assets/logo.png" />
                        </div>
                        <div class="col-12 text-center">
                            <h2> iCaregiver Agency - Login</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <form class="example-form" [formGroup]="inputFormGroup" (submit)="login()">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput type="email" formControlName="email">
                            <mat-error
                                *ngIf="inputFormGroup.get('email')?.hasError('email') && !inputFormGroup.get('email')?.hasError('required')">
                                Please enter a valid email address
                            </mat-error>
                            <mat-error *ngIf="inputFormGroup.get('email')?.hasError('required')">
                                Email is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Password</mat-label>
                            <input matInput [type]="visible ? 'password' : 'text'" formControlName="password">
                            <button type="button" mat-icon-button matSuffix (click)="clickEvent($event)"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="visible">
                                <mat-icon>{{visible ? 'visibility' : 'visibility_off'}}</mat-icon>
                            </button>
                            <mat-error *ngIf="inputFormGroup.get('password')?.hasError('password')">
                                Please should not be less than 6
                            </mat-error>
                            <mat-error *ngIf="inputFormGroup.get('password')?.hasError('required')">
                                Password is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <button class="mt-2" [disabled]="!inputFormGroup.valid" type="submit" mat-raised-button
                            color="primary">Submit</button>
                    </form>
                </div>
                <div class="d-flex justify-content-between mt-3">
                    <a routerLink="/forgot-password">Forgot password ?</a>
                <a routerLink="/register" class="ms-auto float-end">Don't have an account ?</a>
                </div>
            </mat-card>
        </div>
    </div>
</div>

<app-spinner [class.hide]="hideloader"></app-spinner>