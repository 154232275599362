<div class="container-fluid fixed-top">
        <div class="row">
            <div class="col-6">
                <div>
                    <h3>Agency Panel</h3>
                  </div>
            </div>
        <div class="col-6 justify-content-end">
            <!-- <img src="../../../assets/avatar.svg" class="img-fluid avatar" [matMenuTriggerFor]="menu"/>
            <mat-menu #menu="matMenu">
                <button mat-menu-item>
                  <mat-icon>logout</mat-icon>
                  <span>Logout</span>
                </button>
              </mat-menu> -->
                <mat-icon (click)="logout()">logout</mat-icon>
        </div>
        </div>
</div>