<app-spinner [class.hide]="hideloader"></app-spinner>
<app-navbar></app-navbar>
<mat-drawer-container class="example-container">
    
    <mat-drawer mode="side" opened>
        <app-sidebar></app-sidebar>
    </mat-drawer>
    <mat-drawer-content>
        <router-outlet name='dashboard'></router-outlet>
        <!-- <app-google-map></app-google-map> -->
    </mat-drawer-content>
</mat-drawer-container>
