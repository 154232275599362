<div class="container">
    <div class="row">
        <div class="col-12">
            <mat-card class="mat-elevation-z8">
                <div class="row">
                    <div class="col-12">
                        <div style="margin:auto;width:fit-content;">
                            <img src="../../../assets/logo.png" />
                        </div>
                        <div class="col-12 text-center">
                            <h2>iCaregiver Agency - Register</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <form class="example-form" [formGroup]="registerFormGroup" (submit)="register()">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Agency Name</mat-label>
                            <input matInput type="text" formControlName="name">
                            <mat-error *ngIf="registerFormGroup.get('name')?.hasError('required')">
                                Agency name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput type="email" formControlName="email">
                            <mat-error
                                *ngIf="registerFormGroup.get('email')?.hasError('email') && !registerFormGroup.get('email')?.hasError('required')">
                                Please enter a valid email address
                            </mat-error>
                            <mat-error *ngIf="registerFormGroup.get('email')?.hasError('required')">
                                Email is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Password</mat-label>
                            <input matInput type="password" formControlName="password">
                            <mat-error *ngIf="registerFormGroup.get('password')?.hasError('password')">
                                Please should not be less than 6
                            </mat-error>
                            <mat-error *ngIf="registerFormGroup.get('password')?.hasError('required')">
                                Password is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <button class="mt-2" [disabled]="!registerFormGroup.valid" type="submit" mat-raised-button color="primary">Submit</button>
                    </form>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6 p-0">
                        <a routerLink="/login">Already have an account</a>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>

<app-spinner [class.hide]="hideloader"></app-spinner>