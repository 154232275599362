<div class="container mat-elevation-z8">
    <div class="row">
        <div class="col-12">
            <mat-card class="no-border">
                <div class="row">
                    <div class="col-12">
                        <div class="col-12 text-center">
                            <h2>Change Password</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <form class="example-form" [formGroup]="passwordFormGroup" (submit)="changePassword()">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Current Password</mat-label>
                            <input matInput type="password" formControlName="currentPassword">
                            <mat-error *ngIf="passwordFormGroup.get('currentPassword')?.hasError('required')">
                                Current Password is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>New Password</mat-label>
                            <input matInput type="password" formControlName="password">
                            <mat-error *ngIf="passwordFormGroup.get('password')?.hasError('required')">
                                New password is <strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="passwordFormGroup.get('password')?.hasError('minLength')">
                                New password is should not least than 6
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Confirm Password</mat-label>
                            <input matInput type="password" formControlName="confirm_password">
                            <mat-error *ngIf="passwordFormGroup.get('confirm_password')?.hasError('required')">
                                Confirm password is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-error *ngIf="confirmPassword()">
                            Confirm password should matched with <strong>new passowrd</strong>
                        </mat-error>
                        <button class="mt-2" type="submit" [disabled]="!passwordFormGroup.valid || confirmPassword()" mat-raised-button color="primary">Submit</button>
                    </form>
                </div>
            </mat-card>
        </div>
    </div>
</div>

<app-spinner [class.hide]="hideloader"></app-spinner>